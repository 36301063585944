import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  Box,
  LinearProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../store";
import { clearSelectedMember } from "../../store/slices/membersSlice";

interface WelcomeModalProps {
  open: boolean;
  onClose: () => void;
  isSignIn: boolean;
  memberName: string;
}

export const WelcomeModal: React.FC<WelcomeModalProps> = ({
  open,
  onClose,
  isSignIn,
  memberName,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [timeLeft, setTimeLeft] = useState(10);
  const [progress, setProgress] = useState(100);

  useEffect(() => {
    if (open) {
      const timer = setInterval(() => {
        setTimeLeft((prev) => {
          if (prev <= 1) {
            clearInterval(timer);
            handleHomeClick();
            return 0;
          }
          return prev - 1;
        });
        setProgress((prev) => prev - 10);
      }, 1000);

      return () => {
        clearInterval(timer);
        setTimeLeft(10);
        setProgress(100);
      };
    }
  }, [open]);

  const handleHomeClick = () => {
    dispatch(clearSelectedMember());
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 2,
          p: 2,
        },
      }}
    >
      <DialogTitle>
        <Typography
          variant="h4"
          align="center"
          sx={{ fontWeight: "bold", color: "#007EA7" }}
        >
          {isSignIn ? "Welcome Back!" : "See You Later!"}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="h6" align="center" sx={{ mb: 3 }}>
          {isSignIn
            ? `Great to see you again, ${memberName}!`
            : `Thanks for visiting, ${memberName}. Have a great day!`}
        </Typography>
        <Box sx={{ width: "100%", mb: 2 }}>
          <LinearProgress
            variant="determinate"
            value={progress}
            sx={{
              height: 4,
              borderRadius: 2,
              backgroundColor: "rgba(0, 126, 167, 0.08)",
              "& .MuiLinearProgress-bar": {
                backgroundColor: "rgba(0, 126, 167, 0.3)",
              },
            }}
          />
          <Typography
            variant="caption"
            align="center"
            sx={{
              mt: 0.5,
              display: "block",
              color: "text.secondary",
              opacity: 0.7,
            }}
          >
            Going to the Members List in {timeLeft} seconds
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center", gap: 2, pb: 3 }}>
        <Button
          variant="contained"
          onClick={onClose}
          sx={{
            minWidth: 150,
            backgroundColor: "#007EA7",
            "&:hover": {
              backgroundColor: "#006A8E",
            },
          }}
        >
          View Dashboard
        </Button>
        <Button
          variant="outlined"
          onClick={handleHomeClick}
          sx={{
            minWidth: 150,
            borderColor: "#007EA7",
            color: "#007EA7",
            "&:hover": {
              borderColor: "#006A8E",
              backgroundColor: "rgba(0, 126, 167, 0.1)",
            },
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
