import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  Box,
} from "@mui/material";
import { MemberType } from "../../types";

interface SignInDialogProps {
  open: boolean;
  onClose: () => void;
  onSignIn: () => void;
  signInOptions: MemberType[];
  selectedMemberType: MemberType | null;
  onSelectMemberType: (memberType: MemberType) => void;
}

export const SignInDialog: React.FC<SignInDialogProps> = ({
  open,
  onClose,
  onSignIn,
  signInOptions,
  selectedMemberType,
  onSelectMemberType,
}) => (
  <Dialog
    open={open}
    onClose={onClose}
    maxWidth="md"
    fullWidth
    PaperProps={{
      sx: {
        p: 3,
        borderRadius: 2,
      },
    }}
  >
    <DialogTitle>
      <Typography variant="h4" component="div" sx={{ mb: 2 }}>
        Sign In
      </Typography>
    </DialogTitle>
    <DialogContent>
      <Typography variant="h6" sx={{ mb: 3 }}>
        Select how you are signing in:
      </Typography>
      <Box
        sx={{
          display: "flex",
          gap: 2,
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        {signInOptions.map((option) => (
          <Button
            key={option.id}
            variant={
              selectedMemberType?.id === option.id ? "contained" : "outlined"
            }
            color="primary"
            onClick={() => onSelectMemberType(option)}
            sx={{
              mb: 2,
              px: 4,
              py: 2,
              fontSize: "1.1rem",
              borderRadius: 2,
              minWidth: 200,
              transition:
                "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
              "&:hover": {
                transform: "translateY(-2px)",
                boxShadow: 3,
              },
              ...(selectedMemberType?.id === option.id && {
                boxShadow: 3,
                "&:hover": {
                  transform: "none",
                },
              }),
            }}
          >
            {option.group}
          </Button>
        ))}
      </Box>
    </DialogContent>
    <DialogActions sx={{ p: 2, pt: 3, gap: 2 }}>
      <Button
        onClick={onClose}
        size="large"
        sx={{
          fontSize: "1.2rem",
          px: 4,
          py: 1.5,
          minWidth: 140,
          borderRadius: 2,
        }}
      >
        Cancel
      </Button>
      <Button
        onClick={onSignIn}
        disabled={!selectedMemberType}
        variant="contained"
        size="large"
        sx={{
          fontSize: "1.2rem",
          px: 4,
          py: 1.5,
          minWidth: 140,
          borderRadius: 2,
          backgroundColor: selectedMemberType ? "success.main" : "primary.main",
          "&:hover": {
            backgroundColor: selectedMemberType
              ? "success.dark"
              : "primary.dark",
          },
        }}
      >
        Sign In
      </Button>
    </DialogActions>
  </Dialog>
);
