import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  Box,
  Slider,
  Paper,
  LinearProgress,
} from "@mui/material";
import { Activity } from "../../types";

interface SignOutDialogProps {
  open: boolean;
  onClose: () => void;
  onSignOut: () => void;
  activities: Activity[];
  activityTimes: { [key: string]: number };
  onActivityChange: (activityId: string, value: number) => void;
  memberNotes: string;
  onMemberNotesChange: (notes: string) => void;
  totalActivityTime: number;
}

export const SignOutDialog: React.FC<SignOutDialogProps> = ({
  open,
  onClose,
  onSignOut,
  activities,
  activityTimes,
  onActivityChange,
  totalActivityTime,
}) => (
  <Dialog
    open={open}
    onClose={onClose}
    maxWidth="md"
    fullWidth
    PaperProps={{
      sx: {
        p: 3,
        minHeight: "70vh",
        display: "flex",
        flexDirection: "column",
        borderRadius: 2,
      },
    }}
  >
    <DialogTitle>
      <Typography variant="h4" component="div" sx={{ mb: 2 }}>
        Sign Out
      </Typography>
    </DialogTitle>
    <DialogContent sx={{ flex: 1, overflow: "auto" }}>
      <Box sx={{ mb: 4 }}>
        <Typography
          variant="h6"
          sx={{
            mb: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <span>How did you spend your time?</span>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              color:
                totalActivityTime === 100 ? "success.main" : "warning.main",
              fontWeight: "bold",
            }}
          >
            <span>Total: {totalActivityTime}%</span>
          </Box>
        </Typography>
        <LinearProgress
          variant="determinate"
          value={Math.min(totalActivityTime, 100)}
          sx={{
            height: 8,
            borderRadius: 4,
            backgroundColor: "rgba(0,0,0,0.1)",
            "& .MuiLinearProgress-bar": {
              backgroundColor:
                totalActivityTime === 100 ? "success.main" : "warning.main",
            },
          }}
        />
      </Box>

      {activities.map((activity) => (
        <Paper
          key={activity.id}
          elevation={2}
          sx={{
            mb: 3,
            p: 3,
            borderRadius: 2,
            backgroundColor: "background.paper",
            transition:
              "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
            "&:hover": {
              transform: "translateY(-2px)",
              boxShadow: 4,
            },
          }}
        >
          <Typography
            variant="h6"
            sx={{
              mb: 3,
              fontSize: "1.3rem",
              fontWeight: 500,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span>{activity.activity}</span>
            <Box
              sx={{
                color: "primary.main",
                fontSize: "1.4rem",
                fontWeight: "bold",
              }}
            >
              {activityTimes[activity.id] || 0}%
            </Box>
          </Typography>
          <Slider
            value={activityTimes[activity.id] || 0}
            onChange={(_, value) =>
              onActivityChange(activity.id, value as number)
            }
            valueLabelDisplay="auto"
            step={5}
            marks
            min={0}
            max={100}
            sx={{
              mt: 1,
              "& .MuiSlider-thumb": {
                width: 32,
                height: 32,
                backgroundColor: "primary.main",
                "&:hover, &.Mui-focusVisible": {
                  boxShadow: "0 0 0 10px rgba(0, 126, 167, 0.2)",
                },
                "@media (pointer: coarse)": {
                  width: 42,
                  height: 42,
                },
              },
              "& .MuiSlider-track": {
                height: 12,
                borderRadius: 6,
                backgroundColor: "primary.main",
              },
              "& .MuiSlider-rail": {
                height: 12,
                borderRadius: 6,
                backgroundColor: "rgba(0,0,0,0.1)",
              },
              "& .MuiSlider-mark": {
                height: 12,
                width: 2,
                backgroundColor: "rgba(0,0,0,0.15)",
              },
              "& .MuiSlider-valueLabel": {
                fontSize: "1.1rem",
                padding: "10px 14px",
                backgroundColor: "primary.main",
              },
            }}
          />
        </Paper>
      ))}
    </DialogContent>
    <DialogActions sx={{ p: 2, pt: 3, gap: 2 }}>
      <Button
        onClick={onClose}
        size="large"
        sx={{
          fontSize: "1.2rem",
          px: 4,
          py: 1.5,
          minWidth: 140,
          borderRadius: 2,
        }}
      >
        Cancel
      </Button>
      <Button
        onClick={onSignOut}
        disabled={totalActivityTime !== 100}
        variant="contained"
        size="large"
        sx={{
          fontSize: "1.2rem",
          px: 4,
          py: 1.5,
          minWidth: 140,
          borderRadius: 2,
          backgroundColor:
            totalActivityTime === 100 ? "success.main" : "primary.main",
          "&:hover": {
            backgroundColor:
              totalActivityTime === 100 ? "success.dark" : "primary.dark",
          },
        }}
      >
        Sign Out
      </Button>
    </DialogActions>
  </Dialog>
);
