import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Box,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface PointsBreakdownDialogProps {
  open: boolean;
  onClose: () => void;
  pointBreakdown: string;
  totalPoints: number;
}

export const PointsBreakdownDialog: React.FC<PointsBreakdownDialogProps> = ({
  open,
  onClose,
  pointBreakdown,
  totalPoints,
}) => {
  const pointsArray = pointBreakdown.split(", ").map((item) => item.trim());

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h5" component="div" align="center" gutterBottom>
          Forge Points Breakdown
        </Typography>
        <IconButton onClick={onClose} sx={{ color: "white" }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ mt: 2 }}>
          <Typography variant="h6" gutterBottom>
            Total Points: {totalPoints}
          </Typography>
          {pointsArray.map((point, index) => (
            <Typography key={index} variant="body1" gutterBottom>
              {point}
            </Typography>
          ))}
        </Box>
      </DialogContent>
    </Dialog>
  );
};
