import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Box,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface WeeklyStreakDialogProps {
  open: boolean;
  onClose: () => void;
  weeklyStreak: number;
}

export const WeeklyStreakDialog: React.FC<WeeklyStreakDialogProps> = ({
  open,
  onClose,
  weeklyStreak,
}) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h5" component="div" align="center" gutterBottom>
          Weekly Streak Details
        </Typography>
        <IconButton onClick={onClose} sx={{ color: "white" }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            mt: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: 2,
              mb: 3,
            }}
          >
            {weeklyStreak > 0 && (
              <Typography
                sx={{
                  fontSize: "2rem",
                  animation: "pulse 1.5s infinite",
                  "@keyframes pulse": {
                    "0%": { transform: "scale(1)" },
                    "50%": { transform: "scale(1.1)" },
                    "100%": { transform: "scale(1)" },
                  },
                }}
              >
                🔥
              </Typography>
            )}
            <Typography
              variant="h2"
              component="div"
              sx={{
                fontWeight: "bold",
                color: weeklyStreak > 0 ? "#ff6b6b" : "inherit",
              }}
            >
              {weeklyStreak}
            </Typography>
            {weeklyStreak > 0 && (
              <Typography
                sx={{
                  fontSize: "2rem",
                  animation: "pulse 1.5s infinite",
                  "@keyframes pulse": {
                    "0%": { transform: "scale(1)" },
                    "50%": { transform: "scale(1.1)" },
                    "100%": { transform: "scale(1)" },
                  },
                }}
              >
                🔥
              </Typography>
            )}
          </Box>
          <Typography
            variant="h6"
            sx={{
              mb: 2,
              fontWeight: 500,
              color: weeklyStreak > 0 ? "#ff6b6b" : "inherit",
            }}
          >
            Week{weeklyStreak !== 1 ? "s" : ""} in a Row!
          </Typography>
          {weeklyStreak === 0 && (
            <Typography
              variant="body1"
              sx={{
                fontWeight: 500,
                opacity: 0.9,
              }}
            >
              Sign in every week to start your streak! 🎯
            </Typography>
          )}
          {weeklyStreak === 1 && (
            <Typography
              variant="body1"
              sx={{
                fontWeight: 500,
                opacity: 0.9,
              }}
            >
              Amazing start! Come back next week to keep the streak going! 🚀
            </Typography>
          )}
          {weeklyStreak > 1 && (
            <Typography
              variant="body1"
              sx={{
                fontWeight: 500,
                opacity: 0.9,
              }}
            >
              You're absolutely crushing it! Keep the momentum going! 🌟
            </Typography>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};
