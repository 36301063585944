import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useAppDispatch, useAppSelector } from "../../store";
import { setPendingMessage } from "../../store/slices/messageSlice";

interface MessageDialogProps {
  open: boolean;
  onClose: () => void;
}

export const MessageDialog: React.FC<MessageDialogProps> = ({
  open,
  onClose,
}) => {
  const dispatch = useAppDispatch();
  const pendingMessage = useAppSelector(
    (state) => state.message.pendingMessage
  );

  const handleMessageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setPendingMessage(event.target.value));
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h5" component="div" align="center" gutterBottom>
          Send Us a Message
        </Typography>
        <IconButton onClick={handleClose} sx={{ color: "white" }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          multiline
          rows={4}
          label="Your message"
          value={pendingMessage}
          onChange={handleMessageChange}
          sx={{ mt: 2 }}
          placeholder="This message will be sent when you sign out"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};
