import React, { useState, useMemo } from "react";
import { Box, TextField, Typography, Badge, useTheme } from "@mui/material";
import { useAppSelector, useAppDispatch } from "../store";
import { selectMember } from "../store/slices/membersSlice";
import { Member } from "../types";
import { useNavigate } from "react-router-dom";

export const MemberList: React.FC = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");

  const { signedInMembers = [], signedOutMembers = [] } = useAppSelector(
    (state) => state.members
  );

  const filteredSignedInMembers = useMemo(() => {
    return (signedInMembers || [])
      .filter((member) =>
        member.preferredName.toLowerCase().startsWith(searchTerm.toLowerCase())
      )
      .sort((a, b) => a.preferredName.localeCompare(b.preferredName));
  }, [signedInMembers, searchTerm]);

  const filteredSignedOutMembers = useMemo(() => {
    return (signedOutMembers || [])
      .filter((member) =>
        member.preferredName.toLowerCase().startsWith(searchTerm.toLowerCase())
      )
      .sort((a, b) => a.preferredName.localeCompare(b.preferredName));
  }, [signedOutMembers, searchTerm]);

  const handleMemberSelect = (member: Member) => {
    dispatch(selectMember(member));
    navigate("/");
  };

  const MemberCard: React.FC<{ member: Member }> = ({ member }) => {
    const unreadMessages =
      member.messages?.filter((m) => !m.readDate).length || 0;

    if (member.isSignedIn) {
      return (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: 2,
            mb: 3,
            width: "100%",
          }}
        >
          <Badge badgeContent={unreadMessages} color="error" sx={{ flex: 1 }}>
            <Box
              onClick={() => handleMemberSelect(member)}
              sx={{
                width: "100%",
                background: "linear-gradient(90deg, #90471A 0%, #C7611C 100%)",
                color: "white",
                py: 2.5,
                px: 2,
                borderRadius: 2,
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                transition: "opacity 0.2s",
                "&:hover": {
                  opacity: 0.9,
                },
                minHeight: 80,
              }}
            >
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: "2rem",
                  lineHeight: 1,
                }}
              >
                {member.preferredName} {member.lastName}
              </Typography>
            </Box>
          </Badge>
          {member.currentSession?.signInType && (
            <Box
              sx={{
                width: "33%",
                backgroundColor: "#C17947",
                color: "white",
                py: 2.5,
                px: 2,
                borderRadius: 2,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                minHeight: 80,
              }}
            >
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: "2rem",
                  lineHeight: 1,
                }}
              >
                {member.currentSession.signInType.group}
              </Typography>
            </Box>
          )}
        </Box>
      );
    }

    return (
      <Box sx={{ mb: 2, width: "100%" }}>
        <Badge
          badgeContent={unreadMessages}
          color="error"
          sx={{ width: "100%" }}
        >
          <Box
            onClick={() => handleMemberSelect(member)}
            sx={{
              width: "100%",
              backgroundColor: "#444444",
              color: "white",
              py: 2.5,
              px: 2,
              borderRadius: 2,
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              transition: "opacity 0.2s",
              "&:hover": {
                opacity: 0.9,
              },
              minHeight: 80,
            }}
          >
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: "2rem",
                lineHeight: 1,
              }}
            >
              {member.preferredName} {member.lastName}
            </Typography>
          </Box>
        </Badge>
      </Box>
    );
  };

  return (
    <Box sx={{ maxWidth: "800px", margin: "0 auto", padding: 2 }}>
      <TextField
        fullWidth
        variant="outlined"
        placeholder="Search members by first name..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        sx={{ mb: 4 }}
      />

      <Box>
        {filteredSignedInMembers.map((member) => (
          <MemberCard key={member.id} member={member} />
        ))}
      </Box>

      <Box sx={{ mt: 4 }}>
        {filteredSignedOutMembers.map((member) => (
          <MemberCard key={member.id} member={member} />
        ))}
      </Box>
    </Box>
  );
};
