import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface OfflineState {
  isOnline: boolean;
  pendingActions: PendingAction[];
  lastSyncTime: string | null;
  isSyncing: boolean;
}

export interface PendingAction {
  id: string;
  type: "SIGN_IN" | "SIGN_OUT" | "MESSAGE" | "TOGGLE_MESSAGE_READ" | "KUDOS";
  timestamp: string;
  data: any;
}

const initialState: OfflineState = {
  isOnline: true,
  pendingActions: [],
  lastSyncTime: null,
  isSyncing: false,
};

const offlineSlice = createSlice({
  name: "offline",
  initialState,
  reducers: {
    setOnlineStatus: (state, action: PayloadAction<boolean>) => {
      state.isOnline = action.payload;
    },
    addPendingAction: (
      state,
      action: PayloadAction<Omit<PendingAction, "id" | "timestamp">>
    ) => {
      state.pendingActions.push({
        ...action.payload,
        id: Math.random().toString(36).substr(2, 9),
        timestamp: new Date().toISOString(),
      });
    },
    clearPendingActions: (state) => {
      state.pendingActions = [];
    },
    setLastSyncTime: (state, action: PayloadAction<string>) => {
      state.lastSyncTime = action.payload;
    },
    setSyncing: (state, action: PayloadAction<boolean>) => {
      state.isSyncing = action.payload;
    },
  },
});

export const {
  setOnlineStatus,
  addPendingAction,
  clearPendingActions,
  setLastSyncTime,
  setSyncing,
} = offlineSlice.actions;

export default offlineSlice.reducer;
