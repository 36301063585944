import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import membersReducer from "./slices/membersSlice";
import offlineReducer from "./slices/offlineSlice";
import messageReducer from "./slices/messageSlice";
import { RootState } from "../types";

export const store = configureStore({
  reducer: {
    members: membersReducer,
    offline: offlineReducer,
    message: messageReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

// Infer the AppDispatch type from the store
export type AppDispatch = typeof store.dispatch;

// Export hooks that can be reused to resolve types
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppDispatch = () => useDispatch<AppDispatch>();
