import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Typography,
  Box,
  Paper,
  Divider,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Kudos } from "../../types";
import { format } from "date-fns";

interface KudosReceivedDialogProps {
  open: boolean;
  onClose: () => void;
  kudos: Kudos[];
  onGiveKudos: () => void;
}

export const KudosReceivedDialog: React.FC<KudosReceivedDialogProps> = ({
  open,
  onClose,
  kudos = [],
  onGiveKudos,
}) => {
  const formatDate = (date: string) => {
    return format(new Date(date), "MMM d, yyyy 'at' h:mm a");
  };

  const formatSender = (from: any[]) => {
    if (!from || from.length === 0) return "This person is no longer a member";
    const sender = from[0];
    return sender?.preferredName && sender?.lastName
      ? `${sender.preferredName} ${sender.lastName}`
      : "This person is no longer a member";
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h5" component="div" align="center" gutterBottom>
          Kudos Received
        </Typography>
        <IconButton onClick={onClose} size="small" sx={{ color: "white" }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {kudos?.length > 0 ? (
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2, mt: 1 }}>
            {kudos.map((kudos) => (
              <Paper
                key={kudos.id}
                elevation={0}
                sx={{
                  p: 2,
                  background: "rgba(199, 97, 28, 0.1)",
                  borderRadius: 2,
                }}
              >
                <Box sx={{ mb: 1 }}>
                  <Typography variant="subtitle2" color="text.secondary">
                    From: {formatSender(kudos.from)}
                  </Typography>
                  <Typography
                    variant="caption"
                    color="text.secondary"
                    display="block"
                  >
                    {formatDate(kudos.date)}
                  </Typography>
                </Box>
                <Divider sx={{ my: 1, opacity: 0.2 }} />
                <Typography variant="body1">{kudos.message}</Typography>
              </Paper>
            ))}
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 2,
              mt: 4,
              mb: 2,
            }}
          >
            <Typography variant="h6" color="text.secondary">
              You haven't received any kudos yet. Try giving someone kudos!
            </Typography>
            <Button
              variant="contained"
              onClick={onGiveKudos}
              sx={{
                background: "linear-gradient(90deg, #90471A 0%, #C7611C 100%)",
                "&:hover": {
                  opacity: 0.9,
                },
              }}
            >
              Give Kudos
            </Button>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};
