import {
  Member,
  MemberType,
  Activity,
  UseLogEntry,
  MemberStats,
  KudosRequest,
  MemberKudos,
  ForgeLevel,
} from "../types";
import { PendingAction } from "../store/slices/offlineSlice";

const API_BASE_URL =
  process.env.REACT_APP_API_URL || "http://localhost:8080/v1";

class ApiService {
  async getAllMembers(): Promise<Member[]> {
    const response = await fetch(`${API_BASE_URL}/members`);
    if (!response.ok) {
      throw new Error("Failed to fetch members");
    }
    return response.json();
  }

  async getMemberTypes(): Promise<MemberType[]> {
    const response = await fetch(`${API_BASE_URL}/member-types`);
    if (!response.ok) {
      throw new Error("Failed to fetch member types");
    }
    return response.json();
  }

  async getActivities(): Promise<Activity[]> {
    const response = await fetch(`${API_BASE_URL}/activities`);
    if (!response.ok) {
      throw new Error("Failed to fetch activities");
    }
    return response.json();
  }

  async getMemberStats(memberId: string): Promise<MemberStats> {
    const response = await fetch(`${API_BASE_URL}/members/${memberId}/stats`);
    if (!response.ok) {
      throw new Error("Failed to fetch member statistics");
    }
    return response.json();
  }

  async getMemberKudos(memberId: string): Promise<MemberKudos> {
    const response = await fetch(`${API_BASE_URL}/members/${memberId}/kudos`);
    if (!response.ok) {
      throw new Error("Failed to fetch member kudos");
    }
    return response.json();
  }

  async signInMember(memberId: string, memberTypeId: string): Promise<void> {
    const response = await fetch(`${API_BASE_URL}/members/${memberId}/signin`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ memberTypeId }),
    });
    if (!response.ok) {
      throw new Error("Failed to sign in member");
    }
  }

  async signOutMember(
    memberId: string,
    useLogEntry: Omit<UseLogEntry, "id">
  ): Promise<void> {
    const response = await fetch(
      `${API_BASE_URL}/members/${memberId}/signout`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(useLogEntry),
      }
    );
    if (!response.ok) {
      throw new Error("Failed to sign out member");
    }
  }

  async markMessageAsRead(memberId: string, messageId: string): Promise<void> {
    const response = await fetch(
      `${API_BASE_URL}/members/${memberId}/messages/${messageId}/toggle-read`,
      {
        method: "POST",
      }
    );
    if (!response.ok) {
      throw new Error("Failed to mark message as read");
    }
  }

  async sendKudos(kudos: KudosRequest): Promise<void> {
    const response = await fetch(`${API_BASE_URL}/kudos`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(kudos),
    });
    if (!response.ok) {
      throw new Error("Failed to send kudos");
    }
  }

  async getForgeLevels(): Promise<ForgeLevel[]> {
    const response = await fetch(`${API_BASE_URL}/forgelevels`);
    if (!response.ok) {
      throw new Error("Failed to fetch forge levels");
    }
    return response.json();
  }

  async syncOfflineData(actions: PendingAction[]): Promise<void> {
    // Process each action
    for (const action of actions) {
      try {
        switch (action.type) {
          case "SIGN_IN":
            await this.signInMember(
              action.data.memberId,
              action.data.memberTypeId
            );
            break;

          case "SIGN_OUT":
            await this.signOutMember(
              action.data.memberId,
              action.data.useLogEntry
            );
            break;

          case "MESSAGE":
            await this.markMessageAsRead(
              action.data.memberId,
              action.data.messageId
            );
            break;

          case "TOGGLE_MESSAGE_READ":
            await this.markMessageAsRead(
              action.data.memberId,
              action.data.messageId
            );
            break;

          case "KUDOS":
            await this.sendKudos(action.data.kudos);
            break;

          default:
            console.warn(`Unknown action type: ${action.type}`);
            throw new Error(`Unknown action type: ${action.type}`);
        }
      } catch (error) {
        console.error(`Failed to sync action ${action.id}:`, error);
        throw error;
      }
    }
  }
}

export const apiService = new ApiService();
