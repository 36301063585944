import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#C7611C",
      light: "#C7611C",
      dark: "#90471A",
    },
    secondary: {
      main: "#C17947",
      light: "#C17947",
      dark: "#90471A",
    },
    warning: {
      main: "#FF8C00",
    },
    grey: {
      500: "#444444",
    },
    background: {
      default: "#2E2E3A",
      paper: "#232328",
    },
    text: {
      primary: "#FFFFFF",
      secondary: "rgba(255, 255, 255, 0.7)",
    },
  },
  typography: {
    fontFamily: 'darkmode, "Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontSize: "2.5rem",
      fontWeight: 500,
      fontFamily: '"cooper-black-std", serif',
    },
    h2: {
      fontSize: "2rem",
      fontWeight: 500,
      fontFamily: '"cooper-black-std", serif',
    },
    h3: {
      fontSize: "1.75rem",
      fontWeight: 500,
      fontFamily: '"cooper-black-std", serif',
    },
    body1: {
      fontSize: "1rem",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          textTransform: "none",
          fontSize: "1rem",
          padding: "8px 24px",
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 4,
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          backgroundColor: "#232328",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            backgroundColor: "#444444",
            "& fieldset": {
              borderColor: "rgba(255, 255, 255, 0.23)",
            },
            "&:hover fieldset": {
              borderColor: "rgba(255, 255, 255, 0.4)",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#C7611C",
            },
          },
          "& .MuiInputBase-input": {
            color: "#FFFFFF",
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: "#232328",
        },
      },
    },
  },
});
