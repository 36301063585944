import { useEffect } from "react";
import { useAppDispatch } from "../store";
import { setOnlineStatus } from "../store/slices/offlineSlice";

export const useOnlineStatus = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const handleOnline = () => {
      dispatch(setOnlineStatus(true));
    };

    const handleOffline = () => {
      dispatch(setOnlineStatus(false));
    };

    // Set initial status
    dispatch(setOnlineStatus(navigator.onLine));

    // Add event listeners
    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    // Cleanup
    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, [dispatch]);
};
