import React, { useState, useEffect } from "react";
import { Box, Button, Chip } from "@mui/material";
import { useAppSelector, useAppDispatch } from "../store";
import {
  signInMemberAsync,
  signOutMemberAsync,
  sendKudosAsync,
  fetchMemberStatsAsync,
  fetchMemberKudosAsync,
} from "../store/slices/membersSlice";
import {
  setPendingMessage,
  clearPendingMessage,
} from "../store/slices/messageSlice";
import {
  Member,
  MemberType,
  ActivityLogEntry,
  KudosFormData,
  Activity,
} from "../types";
import {
  MemberHeader,
  MemberQualifications,
  MemberMessages,
  SignInDialog,
  SignOutDialog,
  KudosDialog,
  MessageDialog,
  StatsDialog,
  WelcomeModal,
  WeeklyStreakDialog,
} from "./member";

export const MemberDashboard: React.FC = () => {
  const dispatch = useAppDispatch();
  const {
    selectedMember,
    memberTypes,
    activities,
    signedInMembers,
    signedOutMembers,
    memberKudos,
    allMemberStats,
  } = useAppSelector((state) => state.members);

  const [signInDialogOpen, setSignInDialogOpen] = useState(false);
  const [signOutDialogOpen, setSignOutDialogOpen] = useState(false);
  const [kudosDialogOpen, setKudosDialogOpen] = useState(false);
  const [messageDialogOpen, setMessageDialogOpen] = useState(false);
  const [statsDialogOpen, setStatsDialogOpen] = useState(false);
  const [weeklyStreakDialogOpen, setWeeklyStreakDialogOpen] = useState(false);
  const [welcomeModalOpen, setWelcomeModalOpen] = useState(false);
  const [isSignInAction, setIsSignInAction] = useState(false);
  const [selectedMemberType, setSelectedMemberType] =
    useState<MemberType | null>(null);
  const [activityTimes, setActivityTimes] = useState<{ [key: string]: number }>(
    {}
  );
  const [signInOptions, setSignInOptions] = useState<MemberType[]>([]);
  const [sortedActivities, setSortedActivities] = useState<Activity[]>([]);

  // Keep all existing useEffect hooks and helper functions unchanged...
  useEffect(() => {
    if (selectedMember && selectedMember.isSignedIn) {
      if (!allMemberStats[selectedMember.id]) {
        dispatch(fetchMemberStatsAsync(selectedMember.id));
      }
      if (!memberKudos) {
        dispatch(fetchMemberKudosAsync(selectedMember.id));
      }
    }
  }, [
    selectedMember?.id,
    selectedMember?.isSignedIn,
    allMemberStats,
    memberKudos,
    dispatch,
  ]);

  useEffect(() => {
    if (selectedMember && memberTypes) {
      const options = memberTypes.filter((type) =>
        selectedMember.memberTypeIds.includes(type.id)
      );
      setSignInOptions(options);
    }
  }, [selectedMember, memberTypes]);

  useEffect(() => {
    if (activities && selectedMember && allMemberStats[selectedMember.id]) {
      const memberStats = allMemberStats[selectedMember.id];
      if (!memberStats) {
        setSortedActivities(
          [...activities].sort((a, b) => a.activity.localeCompare(b.activity))
        );
        return;
      }

      const activityUsageMap = new Map(
        memberStats.mostUsedAreas.map((usage) => [
          usage.activity,
          usage.percentage,
        ])
      );

      const sorted = [...activities].sort((a, b) => {
        const usageA = activityUsageMap.get(a.activity) || 0;
        const usageB = activityUsageMap.get(b.activity) || 0;

        if (usageA !== usageB) {
          return usageB - usageA;
        }
        return a.activity.localeCompare(b.activity);
      });

      setSortedActivities(sorted);
    } else {
      setSortedActivities(
        [...activities].sort((a, b) => a.activity.localeCompare(b.activity))
      );
    }
  }, [activities, selectedMember, allMemberStats]);

  useEffect(() => {
    return () => {
      dispatch(clearPendingMessage());
    };
  }, [dispatch]);

  if (!selectedMember) return null;

  const handleSignIn = async () => {
    if (selectedMemberType) {
      setSignInDialogOpen(false);
      setIsSignInAction(true);
      setWelcomeModalOpen(true);
      await dispatch(
        signInMemberAsync({
          member: selectedMember,
          signInType: selectedMemberType,
        })
      );
      setSelectedMemberType(null);
    }
  };

  const handleSignInClick = () => {
    if (signInOptions.length === 1) {
      setSelectedMemberType(signInOptions[0]);
      setIsSignInAction(true);
      setWelcomeModalOpen(true);
      dispatch(
        signInMemberAsync({
          member: selectedMember,
          signInType: signInOptions[0],
        })
      );
    } else {
      setSelectedMemberType(signInOptions[0]);
      setSignInDialogOpen(true);
    }
  };

  const handleSignOut = async () => {
    if (!selectedMember.currentSession?.signInTime) {
      console.error("No sign in time found");
      return;
    }

    const signInTime = new Date(selectedMember.currentSession.signInTime);
    const currentTime = new Date();
    const totalHours =
      (currentTime.getTime() - signInTime.getTime()) / (1000 * 60 * 60);

    const activityEntries: ActivityLogEntry[] = Object.entries(activityTimes)
      .filter(([_, value]) => value > 0)
      .map(([activityId, percentage]) => ({
        activity: activities.find((a) => a.id === activityId)!,
        activityTime: (percentage / 100) * totalHours,
      }));

    setSignOutDialogOpen(false);
    setIsSignInAction(false);
    setWelcomeModalOpen(true);

    await dispatch(
      signOutMemberAsync({
        member: selectedMember,
        activities: activityEntries,
      })
    );

    dispatch(clearPendingMessage());
    setActivityTimes({});
  };

  const handleKudosSubmit = async (data: KudosFormData) => {
    await dispatch(
      sendKudosAsync({
        from: selectedMember,
        kudosData: data,
      })
    );
    setKudosDialogOpen(false);
  };

  const handleActivitySliderChange = (activityId: string, newValue: number) => {
    setActivityTimes((prev) => {
      const updatedTimes = { ...prev };
      const oldValue = updatedTimes[activityId] || 0;
      updatedTimes[activityId] = newValue;

      const valueDiff = newValue - oldValue;

      if (valueDiff === 0 || Object.keys(updatedTimes).length <= 1) {
        return updatedTimes;
      }

      const otherSliderIds = Object.entries(updatedTimes)
        .filter(([id, value]) => id !== activityId && value > 0)
        .map(([id]) => id);

      if (otherSliderIds.length === 0) {
        const firstAvailableId = activities.find(
          (a) => a.id !== activityId
        )?.id;
        if (firstAvailableId) {
          updatedTimes[firstAvailableId] = 100 - newValue;
        }
        return updatedTimes;
      }

      const otherSlidersTotal = otherSliderIds.reduce(
        (sum, id) => sum + updatedTimes[id],
        0
      );

      if (otherSlidersTotal > 0) {
        otherSliderIds.forEach((id) => {
          const ratio = updatedTimes[id] / otherSlidersTotal;
          const adjustment = -valueDiff * ratio;
          updatedTimes[id] = Math.max(
            0,
            Math.round(updatedTimes[id] + adjustment)
          );
        });
      }

      const total = Object.values(updatedTimes).reduce(
        (sum, time) => sum + time,
        0
      );
      if (total !== 100 && otherSliderIds.length > 0) {
        const largestSliderId = otherSliderIds.reduce((a, b) =>
          updatedTimes[a] > updatedTimes[b] ? a : b
        );
        updatedTimes[largestSliderId] += 100 - total;
      }

      return updatedTimes;
    });
  };

  const totalActivityTime = Object.values(activityTimes).reduce(
    (sum, time) => sum + time,
    0
  );

  const allMembers = [...signedInMembers, ...signedOutMembers];

  return (
    <Box sx={{ maxWidth: "800px", margin: "0 auto", padding: 2 }}>
      <MemberHeader
        member={selectedMember}
        kudosReceived={memberKudos?.received || []}
        kudosGiven={memberKudos?.given || []}
        allMembers={allMembers}
        onGiveKudos={handleKudosSubmit}
        onViewWeeklyStreak={() => setWeeklyStreakDialogOpen(true)}
      />

      <Button
        fullWidth
        variant="contained"
        onClick={
          selectedMember.isSignedIn
            ? () => setSignOutDialogOpen(true)
            : handleSignInClick
        }
        sx={{
          mb: 2,
          backgroundColor: "#C76000",
          height: "50px", // Increased height
          "&:hover": {
            backgroundColor: "#904606",
          },
          fontWeight: "bold", // Added bold font weight
          fontSize: "1.2rem", // Increased font size
        }}
      >
        {selectedMember.isSignedIn ? "Sign Out" : "Sign In"}
      </Button>

      {selectedMember.isSignedIn && (
        <Box sx={{ display: "flex", gap: 2, mb: 3 }}>
          <Button
            fullWidth
            variant="contained"
            onClick={() => setMessageDialogOpen(true)}
            sx={{
              backgroundColor: "#904606",
              "&:hover": {
                backgroundColor: "#904602",
              },
              height: "40px", // Increased height
              fontWeight: "bold", // Added bold font weight
              fontSize: "1rem", // Increased font size
            }}
          >
            Send Us a Message
          </Button>
          <Box sx={{ position: "relative", width: "100%" }}>
            <Chip
              label="New!"
              color="success"
              size="small"
              sx={{
                position: "absolute",
                top: -12,
                right: -12,
                zIndex: 1,
              }}
            />
            <Button
              fullWidth
              variant="contained"
              onClick={() => setKudosDialogOpen(true)}
              sx={{
                backgroundColor: "#904606",
                "&:hover": {
                  backgroundColor: "#904602",
                },
                height: "40px", // Increased height
                fontWeight: "bold", // Added bold font weight
                fontSize: "1rem", // Increased font size
              }}
            >
              Give Kudos
            </Button>
          </Box>
        </Box>
      )}

      <Box sx={{ mb: 3 }}>
        <MemberMessages member={selectedMember} />
      </Box>
      <MemberQualifications member={selectedMember} />

      <SignInDialog
        open={signInDialogOpen}
        onClose={() => {
          setSignInDialogOpen(false);
          setSelectedMemberType(null);
        }}
        onSignIn={handleSignIn}
        signInOptions={signInOptions}
        selectedMemberType={selectedMemberType}
        onSelectMemberType={setSelectedMemberType}
      />

      <SignOutDialog
        open={signOutDialogOpen}
        onClose={() => setSignOutDialogOpen(false)}
        onSignOut={handleSignOut}
        activities={sortedActivities}
        activityTimes={activityTimes}
        onActivityChange={handleActivitySliderChange}
        memberNotes=""
        onMemberNotesChange={() => {}}
        totalActivityTime={totalActivityTime}
      />

      <KudosDialog
        open={kudosDialogOpen}
        onClose={() => setKudosDialogOpen(false)}
        onSubmit={handleKudosSubmit}
        allMembers={allMembers}
      />

      <MessageDialog
        open={messageDialogOpen}
        onClose={() => setMessageDialogOpen(false)}
      />

      <StatsDialog
        open={statsDialogOpen}
        onClose={() => setStatsDialogOpen(false)}
      />

      <WeeklyStreakDialog
        open={weeklyStreakDialogOpen}
        onClose={() => setWeeklyStreakDialogOpen(false)}
        weeklyStreak={selectedMember.weeklyStreak}
      />

      <WelcomeModal
        open={welcomeModalOpen}
        onClose={() => setWelcomeModalOpen(false)}
        isSignIn={isSignInAction}
        memberName={selectedMember.preferredName}
      />
    </Box>
  );
};
