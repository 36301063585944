import React from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  useTheme,
} from "@mui/material";
import { Member } from "../../types";

interface MemberQualificationsProps {
  member: Member;
}

export const MemberQualifications: React.FC<MemberQualificationsProps> = ({
  member,
}) => {
  const theme = useTheme();

  return (
    <Card sx={{ mb: 3, backgroundColor: "#444444", borderRadius: 2 }}>
      <CardContent>
        <Typography variant="h6" gutterBottom sx={{ color: "#ffffff" }}>
          Qualifications
        </Typography>
        <Grid container spacing={2}>
          {Array.isArray(member.qualifications) &&
            member.qualifications.map((qual, index) => (
              <Grid item xs={6} key={index}>
                <Card
                  elevation={3}
                  sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: 2,
                    backgroundColor: "#555555",
                    borderRadius: 2,
                    boxShadow: `0 4px 8px rgba(0, 0, 0, 0.2)`,
                  }}
                >
                  <Typography
                    variant="body1"
                    align="center"
                    sx={{
                      color: "#ffffff",
                      fontSize: "1rem",
                      fontWeight: 500,
                      lineHeight: 1.4,
                    }}
                  >
                    {qual}
                  </Typography>
                </Card>
              </Grid>
            ))}
        </Grid>
      </CardContent>
    </Card>
  );
};
