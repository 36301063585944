import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  Paper,
  LinearProgress,
  Grid,
  Box,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useAppSelector } from "../../store";

interface StatsDialogProps {
  open: boolean;
  onClose: () => void;
}

export const StatsDialog: React.FC<StatsDialogProps> = ({ open, onClose }) => {
  const theme = useTheme();
  const selectedMember = useAppSelector(
    (state) => state.members.selectedMember
  );
  const allMemberStats = useAppSelector(
    (state) => state.members.allMemberStats
  );

  if (!selectedMember) return null;

  const memberStats = allMemberStats[selectedMember.id];

  if (!memberStats) {
    return (
      <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
        <DialogTitle>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h6">Member Stats</Typography>
            <IconButton onClick={onClose} size="small" sx={{ color: "white" }}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Typography>
            No stats available for {selectedMember.preferredName}
          </Typography>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">
            {selectedMember.preferredName}'s Stats
          </Typography>
          <IconButton onClick={onClose} size="small" sx={{ color: "white" }}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper sx={{ p: 3, backgroundColor: "#444444" }}>
              <Typography variant="h6" sx={{ mb: 2, color: "white" }}>
                Most Used Areas
              </Typography>
              {memberStats.mostUsedAreas.map((area) => (
                <Box key={area.activity} sx={{ mb: 2 }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      mb: 1,
                    }}
                  >
                    <Typography sx={{ color: "white" }}>
                      {area.activity}
                    </Typography>
                    <Typography sx={{ color: "white" }}>
                      {area.percentage}%
                    </Typography>
                  </Box>
                  <LinearProgress
                    variant="determinate"
                    value={area.percentage}
                    sx={{
                      height: 8,
                      borderRadius: 4,
                      backgroundColor: "rgba(255, 255, 255, 0.1)",
                      "& .MuiLinearProgress-bar": {
                        backgroundColor: "#007EA7",
                        borderRadius: 4,
                      },
                    }}
                  />
                </Box>
              ))}
            </Paper>
          </Grid>

          <Grid item xs={12} md={6}>
            <Paper sx={{ p: 3, backgroundColor: "#444444" }}>
              <Typography variant="h6" sx={{ mb: 2, color: "white" }}>
                Common Days
              </Typography>
              {memberStats.commonDays.map((day) => (
                <Box key={day.day} sx={{ mb: 2 }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      mb: 1,
                    }}
                  >
                    <Typography sx={{ color: "white" }}>{day.day}</Typography>
                    <Typography sx={{ color: "white" }}>
                      {day.count} visits
                    </Typography>
                  </Box>
                  <LinearProgress
                    variant="determinate"
                    value={
                      (day.count /
                        Math.max(
                          ...memberStats.commonDays.map((d) => d.count)
                        )) *
                      100
                    }
                    sx={{
                      height: 8,
                      borderRadius: 4,
                      backgroundColor: "rgba(255, 255, 255, 0.1)",
                      "& .MuiLinearProgress-bar": {
                        backgroundColor: "#007EA7",
                        borderRadius: 4,
                      },
                    }}
                  />
                </Box>
              ))}
            </Paper>
          </Grid>

          <Grid item xs={12} md={6}>
            <Paper sx={{ p: 3, backgroundColor: "#444444" }}>
              <Typography variant="h6" sx={{ mb: 2, color: "white" }}>
                Common Times
              </Typography>
              {memberStats.commonTimes.map((time) => (
                <Box key={time.hour} sx={{ mb: 2 }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      mb: 1,
                    }}
                  >
                    <Typography sx={{ color: "white" }}>
                      {time.hour.toString().padStart(2, "0")}:00 -{" "}
                      {(time.hour + 1).toString().padStart(2, "0")}:00
                    </Typography>
                    <Typography sx={{ color: "white" }}>
                      {time.count} visits
                    </Typography>
                  </Box>
                  <LinearProgress
                    variant="determinate"
                    value={
                      (time.count /
                        Math.max(
                          ...memberStats.commonTimes.map((t) => t.count)
                        )) *
                      100
                    }
                    sx={{
                      height: 8,
                      borderRadius: 4,
                      backgroundColor: "rgba(255, 255, 255, 0.1)",
                      "& .MuiLinearProgress-bar": {
                        backgroundColor: "#007EA7",
                        borderRadius: 4,
                      },
                    }}
                  />
                </Box>
              ))}
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <Paper sx={{ p: 3, backgroundColor: "#444444" }}>
              <Typography variant="h6" sx={{ mb: 1, color: "white" }}>
                Summary
              </Typography>
              <Box sx={{ display: "flex", gap: 4, flexWrap: "wrap" }}>
                <Box>
                  <Typography
                    variant="body2"
                    sx={{ color: "rgba(255, 255, 255, 0.7)" }}
                  >
                    Total Time
                  </Typography>
                  <Typography variant="h5" sx={{ color: "white" }}>
                    {memberStats.totalTime.toFixed(1)} hours
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant="body2"
                    sx={{ color: "rgba(255, 255, 255, 0.7)" }}
                  >
                    Average Duration
                  </Typography>
                  <Typography variant="h5" sx={{ color: "white" }}>
                    {memberStats.averageDuration.toFixed(0)} minutes
                  </Typography>
                </Box>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
