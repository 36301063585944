import React from "react";
import {
  Box,
  CircularProgress,
  Typography,
  SxProps,
  Theme,
} from "@mui/material";

interface LoadingOverlayProps {
  message?: string;
  sx?: SxProps<Theme>;
}

export const LoadingOverlay: React.FC<LoadingOverlayProps> = ({
  message,
  sx,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 1,
        ...(sx || {}),
      }}
    >
      <CircularProgress size={20} sx={{ color: "inherit" }} />
      {message && (
        <Typography variant="body2" sx={{ fontWeight: 500 }}>
          {message}
        </Typography>
      )}
    </Box>
  );
};
