import React from "react";
import {
  Box,
  AppBar,
  Toolbar,
  Typography,
  Container,
  useTheme,
  Snackbar,
  Alert,
  IconButton,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import WifiIcon from "@mui/icons-material/Wifi";
import WifiOffIcon from "@mui/icons-material/WifiOff";
import SyncIcon from "@mui/icons-material/Sync";
import { useAppSelector, useAppDispatch } from "../store";
import { useOnlineStatus } from "../hooks/useOnlineStatus";
import { useLocation, useNavigate } from "react-router-dom";
import { clearSelectedMember } from "../store/slices/membersSlice";
import { LoadingOverlay } from "./LoadingOverlay";
import { useDataSync } from "../hooks/useDataSync";

interface LayoutProps {
  children: React.ReactNode;
}

export const Layout: React.FC<LayoutProps> = ({ children }) => {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isOnline = useAppSelector((state) => state.offline.isOnline);
  const selectedMember = useAppSelector(
    (state) => state.members.selectedMember
  );
  const isLoadingStats = useAppSelector(
    (state) => state.members.isLoadingStats
  );
  const isLoadingKudos = useAppSelector((state) => state.members.loading);
  const [showSnackbar, setShowSnackbar] = React.useState(false);

  // Initialize online status monitoring
  useOnlineStatus();

  // Initialize data sync hook
  const { syncData, isSyncing } = useDataSync();

  // Show snackbar when online status changes
  React.useEffect(() => {
    setShowSnackbar(true);
  }, [isOnline]);

  const handleSnackbarClose = () => {
    setShowSnackbar(false);
  };

  const handleBack = () => {
    if (selectedMember) {
      dispatch(clearSelectedMember());
    } else {
      navigate(-1);
    }
  };

  const showBackButton = location.pathname !== "/" || selectedMember;

  const handleSync = () => {
    syncData();
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
      <AppBar position="static">
        <Toolbar
          sx={{
            display: "flex",
            alignItems: "center",
            height: "64px", // Fixed height for the toolbar
            position: "relative", // Enable absolute positioning of children
          }}
        >
          {showBackButton && (
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleBack}
              sx={{
                mr: 2,
                position: "absolute",
                left: 16,
              }}
            >
              <ArrowBackIcon />
            </IconButton>
          )}
          <Typography
            variant="h1"
            component="div"
            sx={{
              fontSize: "1.5rem",
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
              width: "auto",
            }}
          >
            The Curious Forge
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
              position: "absolute",
              right: 16,
            }}
          >
            {isSyncing && (
              <LoadingOverlay
                message="Syncing..."
                sx={{
                  backgroundColor: "transparent",
                  color: "white",
                  padding: 1,
                  "& .MuiCircularProgress-root": {
                    width: "20px !important",
                    height: "20px !important",
                  },
                  "& .MuiTypography-root": {
                    fontSize: "0.875rem",
                  },
                }}
              />
            )}
            {isOnline ? (
              <WifiIcon
                sx={{
                  color: theme.palette.success.main,
                  fontSize: 20,
                }}
              />
            ) : (
              <WifiOffIcon
                sx={{
                  color: theme.palette.error.main,
                  fontSize: 20,
                }}
              />
            )}
            {location.pathname === "/" && (
              <IconButton
                color="inherit"
                onClick={handleSync}
                disabled={isSyncing}
                sx={{
                  ml: 2,
                }}
              >
                <SyncIcon />
              </IconButton>
            )}
          </Box>
        </Toolbar>
      </AppBar>

      <Container
        maxWidth={false}
        sx={{
          flexGrow: 1,
          py: 3,
          backgroundColor: theme.palette.background.default,
        }}
      >
        {children}
      </Container>

      <Snackbar
        open={showSnackbar}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={isOnline ? "success" : "warning"}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {isOnline
            ? "Connected - Changes will sync automatically"
            : "Offline - Changes will be saved locally"}
        </Alert>
      </Snackbar>
    </Box>
  );
};
