import { useEffect, useCallback, useState } from "react";
import { useAppDispatch, useAppSelector } from "../store";
import { apiService } from "../services/apiService";
import {
  setSignedInMembers,
  setSignedOutMembers,
  setMemberTypes,
  setActivities,
  setLoading,
  setError,
  setForgeLevels,
  fetchForgeLevelsAsync,
} from "../store/slices/membersSlice";
import {
  clearPendingActions,
  setLastSyncTime,
  setSyncing,
} from "../store/slices/offlineSlice";

export const useDataSync = () => {
  const dispatch = useAppDispatch();
  const { isOnline, pendingActions } = useAppSelector((state) => state.offline);
  const [isSyncing, setIsSyncing] = useState(false);

  // Expose syncData function
  const syncData = useCallback(async () => {
    if (!isOnline) {
      console.error("Cannot sync data while offline");
      dispatch(setError("Cannot sync data while offline"));
      return;
    }

    try {
      dispatch(setSyncing(true));
      setIsSyncing(true);

      // Refresh data after sync
      const [members, memberTypes, activities, forgeLevels] = await Promise.all(
        [
          apiService.getAllMembers(),
          apiService.getMemberTypes(),
          apiService.getActivities(),
          apiService.getForgeLevels(),
        ]
      );

      const signedInMembers = members.filter((member) => member.isSignedIn);
      const signedOutMembers = members.filter((member) => !member.isSignedIn);

      dispatch(setSignedInMembers(signedInMembers));
      dispatch(setSignedOutMembers(signedOutMembers));
      dispatch(setMemberTypes(memberTypes));
      dispatch(setActivities(activities));
      dispatch(setForgeLevels(forgeLevels));
      dispatch(setLastSyncTime(new Date().toISOString()));
    } catch (error) {
      console.error("Error syncing data:", error);
      dispatch(setError("Failed to sync data"));
    } finally {
      dispatch(setSyncing(false));
      setIsSyncing(false);
    }
  }, [isOnline, dispatch]);

  // Initial data fetch
  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(setLoading(true));

        // Fetch all data in parallel
        const [members, memberTypes, activities, forgeLevels] =
          await Promise.all([
            apiService.getAllMembers(),
            apiService.getMemberTypes(),
            apiService.getActivities(),
            apiService.getForgeLevels(),
          ]);

        // Split members into signed in and signed out
        const signedInMembers = members.filter((member) => member.isSignedIn);
        const signedOutMembers = members.filter((member) => !member.isSignedIn);

        // Update store with fetched data
        dispatch(setSignedInMembers(signedInMembers));
        dispatch(setSignedOutMembers(signedOutMembers));
        dispatch(setMemberTypes(memberTypes));
        dispatch(setActivities(activities));
        dispatch(setForgeLevels(forgeLevels));
        dispatch(setLastSyncTime(new Date().toISOString()));
      } catch (error) {
        console.error("Error fetching initial data:", error);
        dispatch(setError("Failed to fetch initial data"));
      } finally {
        dispatch(setLoading(false));
      }
    };

    fetchData();
  }, []); // Only run on mount

  // Handle offline/online sync
  useEffect(() => {
    const syncOfflineData = async () => {
      if (!isOnline || pendingActions.length === 0) return;

      try {
        dispatch(setSyncing(true));
        setIsSyncing(true);

        // Process each pending action in sequence
        for (const action of pendingActions) {
          try {
            await apiService.syncOfflineData([action]);
          } catch (error) {
            console.error(`Failed to sync action ${action.id}:`, error);
            // Don't throw here, continue with other actions
            continue;
          }
        }

        // Only clear pending actions after all have been processed
        dispatch(clearPendingActions());

        // Wait a short delay to ensure backend has processed all changes
        await new Promise((resolve) => setTimeout(resolve, 1000));

        // Refresh data after sync
        const [members, memberTypes, activities, forgeLevels] =
          await Promise.all([
            apiService.getAllMembers(),
            apiService.getMemberTypes(),
            apiService.getActivities(),
            apiService.getForgeLevels(),
          ]);

        const signedInMembers = members.filter((member) => member.isSignedIn);
        const signedOutMembers = members.filter((member) => !member.isSignedIn);

        dispatch(setSignedInMembers(signedInMembers));
        dispatch(setSignedOutMembers(signedOutMembers));
        dispatch(setMemberTypes(memberTypes));
        dispatch(setActivities(activities));
        dispatch(setForgeLevels(forgeLevels));
        dispatch(setLastSyncTime(new Date().toISOString()));
      } catch (error) {
        console.error("Error syncing offline data:", error);
        dispatch(setError("Failed to sync offline changes"));
      } finally {
        dispatch(setSyncing(false));
        setIsSyncing(false);
      }
    };

    syncOfflineData();
  }, [isOnline, pendingActions.length]); // Run when online status changes or pending actions change

  // Auto-sync every 10 minutes when online
  useEffect(() => {
    let syncInterval: NodeJS.Timeout;

    if (isOnline) {
      syncInterval = setInterval(() => {
        syncData();
      }, 10 * 60 * 1000); // 10 minutes
    }

    return () => {
      clearInterval(syncInterval);
    };
  }, [isOnline, syncData]);

  return { syncData, isSyncing };
};
