import React, { useState } from "react";
import { Box, Card, CardContent, Typography, Grid, Chip } from "@mui/material";
import { Member, Kudos, KudosFormData, RootState } from "../../types";
import { PointsBreakdownDialog } from "./PointsBreakdownDialog";
import { KudosReceivedDialog } from "./KudosReceivedDialog";
import { KudosGivenDialog } from "./KudosGivenDialog";
import { KudosDialog } from "./KudosDialog";
import { ForgeLevelDialog } from "./ForgeLevelDialog";
import { useSelector } from "react-redux";

interface MemberHeaderProps {
  member: Member;
  kudosReceived: Kudos[];
  kudosGiven: Kudos[];
  allMembers: Member[];
  onGiveKudos: (data: KudosFormData) => void;
  onViewWeeklyStreak: () => void;
}

const formatTime = (dateString: string) => {
  const date = new Date(dateString);
  return date.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  });
};

interface StatBoxProps {
  label: string;
  value: number;
  onClick?: () => void;
}

const StatBox: React.FC<StatBoxProps> = ({ label, value, onClick }) => (
  <Box
    sx={{
      background: "#4A90E2",
      padding: 2,
      borderRadius: 2,
      textAlign: "center",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      cursor: onClick ? "pointer" : "default",
      "&:hover": onClick
        ? {
            opacity: 0.9,
          }
        : {},
    }}
    onClick={onClick}
  >
    <Typography variant="h1" sx={{ color: "#FFCE01", mb: 1 }}>
      {value || 0}
    </Typography>
    <Typography variant="body2" sx={{ color: "#FFFFFF", opacity: 0.8 }}>
      {label}
    </Typography>
  </Box>
);

export const MemberHeader: React.FC<MemberHeaderProps> = ({
  member,
  kudosReceived,
  kudosGiven,
  allMembers,
  onGiveKudos,
  onViewWeeklyStreak,
}) => {
  const [pointsDialogOpen, setPointsDialogOpen] = useState(false);
  const [kudosReceivedDialogOpen, setKudosReceivedDialogOpen] = useState(false);
  const [kudosGivenDialogOpen, setKudosGivenDialogOpen] = useState(false);
  const [giveKudosDialogOpen, setGiveKudosDialogOpen] = useState(false);
  const [forgeLevelDialogOpen, setForgeLevelDialogOpen] = useState(false);

  const forgeLevels = useSelector(
    (state: RootState) => state.members.forgeLevels
  );

  const handleGiveKudos = (data: KudosFormData) => {
    onGiveKudos(data);
    setGiveKudosDialogOpen(false);
  };

  return (
    <>
      <Card
        sx={{
          mb: 3,
          background: "linear-gradient(90deg, #0047AB 0%, #2A75BC 100%)",
        }}
      >
        <CardContent>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "flex-start",
              gap: 1,
              mb: 3,
            }}
          >
            <Box>
              <Typography
                variant="h1"
                sx={{ fontSize: "2.5rem", color: "#FFFFFF" }}
              >
                {member.preferredName} {member.lastName}
              </Typography>
              {member.isSignedIn && member.currentSession?.signInType && (
                <Typography
                  variant="h6"
                  sx={{
                    color: "white",
                    opacity: 0.9,
                    textAlign: "left",
                    mb: 2,
                  }}
                >
                  Signed in as {member.currentSession.signInType.group} • Since{" "}
                  {formatTime(member.currentSession.signInTime)}
                </Typography>
              )}
            </Box>
            {member.forgeLevelName && (
              <Chip
                label={
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    Forge Level: {member.forgeLevelName}
                  </Typography>
                }
                color="primary"
                variant="outlined"
                onClick={() => setForgeLevelDialogOpen(true)}
                sx={{
                  mt: 1,
                  mb: 1,
                  backgroundColor: "#FFFFFF",
                  padding: "0 20px",
                  fontSize: "1.2rem",
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: "rgba(255, 255, 255, 0.9)",
                  },
                }}
              />
            )}
          </Box>

          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={3}>
              <StatBox
                label="Forge Points"
                value={member.totalPoints}
                onClick={() => setPointsDialogOpen(true)}
              />
            </Grid>
            <Grid item xs={3}>
              <StatBox
                label="Weekly Streak"
                value={member.weeklyStreak}
                onClick={onViewWeeklyStreak}
              />
            </Grid>
            <Grid item xs={3}>
              <StatBox
                label="Kudos Received"
                value={member.numberKudosGiven}
                onClick={() => setKudosReceivedDialogOpen(true)}
              />
            </Grid>
            <Grid item xs={3}>
              <StatBox
                label="Kudos Given"
                value={member.numberKudosReceived}
                onClick={() => setKudosGivenDialogOpen(true)}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <PointsBreakdownDialog
        open={pointsDialogOpen}
        onClose={() => setPointsDialogOpen(false)}
        pointBreakdown={member.pointBreakdown}
        totalPoints={member.totalPoints}
      />

      <KudosReceivedDialog
        open={kudosReceivedDialogOpen}
        onClose={() => setKudosReceivedDialogOpen(false)}
        kudos={kudosReceived}
        onGiveKudos={() => setGiveKudosDialogOpen(true)}
      />

      <KudosGivenDialog
        open={kudosGivenDialogOpen}
        onClose={() => setKudosGivenDialogOpen(false)}
        kudos={kudosGiven}
        onGiveKudos={() => setGiveKudosDialogOpen(true)}
      />

      <KudosDialog
        open={giveKudosDialogOpen}
        onClose={() => setGiveKudosDialogOpen(false)}
        onSubmit={handleGiveKudos}
        allMembers={allMembers}
      />

      <ForgeLevelDialog
        open={forgeLevelDialogOpen}
        onClose={() => setForgeLevelDialogOpen(false)}
        forgeLevels={forgeLevels}
        currentForgeLevel={member.forgeLevel}
        totalPoints={member.totalPoints}
      />
    </>
  );
};
