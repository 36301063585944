import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  Typography,
  Box,
  useTheme,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { ForgeLevel } from "../../types";

interface ForgeLevelDialogProps {
  open: boolean;
  onClose: () => void;
  forgeLevels: ForgeLevel[];
  currentForgeLevel: string; // ID of the current forge level
  totalPoints: number;
}

export const ForgeLevelDialog: React.FC<ForgeLevelDialogProps> = ({
  open,
  onClose,
  forgeLevels,
  currentForgeLevel,
  totalPoints,
}) => {
  const theme = useTheme();

  // Sort forge levels by minimum points
  const sortedLevels = [...forgeLevels].sort(
    (a, b) => a.minimumPoints - b.minimumPoints
  );

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h5" component="div" align="center" gutterBottom>
          Forge Levels
        </Typography>
        <IconButton
          onClick={onClose}
          sx={{ color: theme.palette.common.white }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <List>
          {sortedLevels.map((level) => {
            const isCurrentLevel = level.id === currentForgeLevel;
            const isAchieved = totalPoints >= level.minimumPoints;

            return (
              <ListItem
                key={level.id}
                sx={{
                  backgroundColor: isCurrentLevel
                    ? "rgba(74, 144, 226, 0.1)"
                    : "transparent",
                  borderRadius: 1,
                  mb: 1,
                  border: isCurrentLevel
                    ? `2px solid ${theme.palette.primary.main}`
                    : "none",
                }}
              >
                <Box sx={{ width: "100%" }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      mb: 0.5,
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        color: isAchieved
                          ? theme.palette.success.main
                          : theme.palette.text.primary,
                        fontWeight: isCurrentLevel ? "bold" : "normal",
                      }}
                    >
                      {level.levelName}
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        color: isAchieved
                          ? theme.palette.success.main
                          : theme.palette.text.secondary,
                      }}
                    >
                      {level.minimumPoints} points
                    </Typography>
                  </Box>
                  {isCurrentLevel && (
                    <Typography
                      variant="body2"
                      sx={{
                        color: theme.palette.primary.main,
                        fontStyle: "italic",
                      }}
                    >
                      Current Level
                    </Typography>
                  )}
                </Box>
              </ListItem>
            );
          })}
        </List>
      </DialogContent>
    </Dialog>
  );
};
