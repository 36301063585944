import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import {
  Member,
  MembersState,
  MemberType,
  ActivityLogEntry,
  Activity,
  UseLogEntry,
  MemberStats,
  Kudos,
  KudosFormData,
  KudosRequest,
  MemberKudos,
  ForgeLevel,
} from "../../types";
import { apiService } from "../../services/apiService";
import { addPendingAction } from "./offlineSlice";
import { AppDispatch } from "..";

const initialState: MembersState = {
  signedInMembers: [],
  signedOutMembers: [],
  selectedMember: null,
  memberTypes: [],
  activities: [],
  loading: false,
  error: null,
  memberStats: null,
  kudos: [],
  memberKudos: null,
  allMemberStats: {},
  isLoadingStats: false,
  forgeLevels: [], // Initialize the new field
};

// Thunk action for fetching member stats
export const fetchMemberStatsAsync = createAsyncThunk<
  { memberId: string; stats: MemberStats },
  string,
  { state: { members: MembersState } }
>("members/fetchMemberStatsAsync", async (memberId) => {
  const stats = await apiService.getMemberStats(memberId);
  return { memberId, stats };
});

// Thunk action for fetching member kudos
export const fetchMemberKudosAsync = createAsyncThunk<
  MemberKudos,
  string,
  { dispatch: AppDispatch }
>("members/fetchMemberKudosAsync", async (memberId) => {
  const kudos = await apiService.getMemberKudos(memberId);
  return kudos;
});

// Thunk action for sending kudos
export const sendKudosAsync = createAsyncThunk<
  void,
  { from: Member; kudosData: KudosFormData },
  { dispatch: AppDispatch; state: { offline: { isOnline: boolean } } }
>(
  "members/sendKudosAsync",
  async ({ from, kudosData }, { dispatch, getState }) => {
    const { isOnline } = getState().offline;
    const kudosRequest: KudosRequest = {
      from: from.id,
      to: kudosData.recipients.map((r) => r.id),
      message: kudosData.message,
    };

    if (!isOnline) {
      dispatch(
        addPendingAction({
          type: "KUDOS",
          data: kudosRequest,
        })
      );
    } else {
      await apiService.sendKudos(kudosRequest);
    }

    dispatch(
      addKudos({
        id: Math.random().toString(36).substr(2, 9),
        from: [from],
        to: kudosData.recipients,
        message: kudosData.message,
        date: new Date().toISOString(),
      })
    );
  }
);

// Thunk action for signing in a member
export const signInMemberAsync = createAsyncThunk<
  void,
  { member: Member; signInType: MemberType },
  { dispatch: AppDispatch; state: { offline: { isOnline: boolean } } }
>(
  "members/signInMemberAsync",
  async ({ member, signInType }, { dispatch, getState }) => {
    const { isOnline } = getState().offline;

    if (!isOnline) {
      dispatch(
        addPendingAction({
          type: "SIGN_IN",
          data: {
            memberId: member.id,
            memberTypeId: signInType.id,
          },
        })
      );
    } else {
      await apiService.signInMember(member.id, signInType.id);
    }

    dispatch(signInMember({ member, signInType }));
  }
);

// Thunk action for signing out a member
export const signOutMemberAsync = createAsyncThunk<
  void,
  {
    member: Member;
    activities: ActivityLogEntry[];
    memberNotes?: string;
  },
  { dispatch: AppDispatch; state: { offline: { isOnline: boolean } } }
>(
  "members/signOutMemberAsync",
  async ({ member, activities, memberNotes }, { dispatch, getState }) => {
    const { isOnline } = getState().offline;

    const useLogEntry: Omit<UseLogEntry, "id"> = {
      signInTime: member.currentSession!.signInTime,
      signOutTime: new Date().toISOString(),
      signedInAs: member.currentSession!.signInType,
      activities,
      memberNotes: memberNotes || null,
    };

    if (!isOnline) {
      dispatch(
        addPendingAction({
          type: "SIGN_OUT",
          data: {
            memberId: member.id,
            useLogEntry,
          },
        })
      );
    } else {
      await apiService.signOutMember(member.id, useLogEntry);
    }

    dispatch(signOutMember({ member, activities, memberNotes }));
  }
);

// Thunk action for toggling message read status
export const toggleMessageReadStatusAsync = createAsyncThunk<
  void,
  {
    memberId: string;
    messageId: string;
  },
  { dispatch: AppDispatch; state: { offline: { isOnline: boolean } } }
>(
  "members/toggleMessageReadStatusAsync",
  async ({ memberId, messageId }, { dispatch, getState }) => {
    const { isOnline } = getState().offline;

    if (!isOnline) {
      dispatch(
        addPendingAction({
          type: "MESSAGE",
          data: {
            memberId,
            messageId,
          },
        })
      );
    } else {
      await apiService.markMessageAsRead(memberId, messageId);
    }
  }
);

// Thunk action for fetching forge levels
export const fetchForgeLevelsAsync = createAsyncThunk<
  ForgeLevel[],
  void,
  { dispatch: AppDispatch }
>("members/fetchForgeLevelsAsync", async () => {
  const forgeLevels = await apiService.getForgeLevels();
  return forgeLevels;
});

const membersSlice = createSlice({
  name: "members",
  initialState,
  reducers: {
    setSignedInMembers: (state, action: PayloadAction<Member[]>) => {
      state.signedInMembers = action.payload;
    },
    setSignedOutMembers: (state, action: PayloadAction<Member[]>) => {
      state.signedOutMembers = action.payload;
    },
    setMemberTypes: (state, action: PayloadAction<MemberType[]>) => {
      state.memberTypes = action.payload;
    },
    setActivities: (state, action: PayloadAction<Activity[]>) => {
      state.activities = action.payload;
    },
    selectMember: (state, action: PayloadAction<Member>) => {
      state.selectedMember = action.payload;
    },
    clearSelectedMember: (state) => {
      state.selectedMember = null;
      state.memberStats = null;
      state.memberKudos = null;
    },
    addKudos: (state, action: PayloadAction<Kudos>) => {
      state.kudos.push(action.payload);
    },
    setKudos: (state, action: PayloadAction<Kudos[]>) => {
      state.kudos = action.payload;
    },
    setMemberKudos: (state, action: PayloadAction<MemberKudos>) => {
      state.memberKudos = action.payload;
    },
    signInMember: (
      state,
      action: PayloadAction<{ member: Member; signInType: MemberType }>
    ) => {
      const { member, signInType } = action.payload;
      const updatedMember = {
        ...member,
        isSignedIn: true,
        currentSession: {
          signInTime: new Date().toISOString(),
          signInType,
        },
      };
      state.signedInMembers.push(updatedMember);
      state.signedOutMembers = state.signedOutMembers.filter(
        (m) => m.id !== member.id
      );
      if (state.selectedMember?.id === member.id) {
        state.selectedMember = updatedMember;
      }
    },
    signOutMember: (
      state,
      action: PayloadAction<{
        member: Member;
        activities: ActivityLogEntry[];
        memberNotes?: string;
      }>
    ) => {
      const { member } = action.payload;
      const updatedMember = {
        ...member,
        isSignedIn: false,
        currentSession: undefined,
      };
      state.signedOutMembers.push(updatedMember);
      state.signedInMembers = state.signedInMembers.filter(
        (m) => m.id !== member.id
      );
      if (state.selectedMember?.id === member.id) {
        state.selectedMember = updatedMember;
      }
    },
    toggleMessageReadStatus: (
      state,
      action: PayloadAction<{
        memberId: string;
        messageId: string;
      }>
    ) => {
      const updateMemberMessages = (member: Member) => {
        const message = member.messages?.find(
          (m) => m.id === action.payload.messageId
        );
        if (message) {
          message.readDate = message.readDate ? null : new Date().toISOString();
        }
      };

      // Update in signedInMembers
      const signedInMember = state.signedInMembers.find(
        (m) => m.id === action.payload.memberId
      );
      if (signedInMember) {
        updateMemberMessages(signedInMember);
      }

      // Update in signedOutMembers
      const signedOutMember = state.signedOutMembers.find(
        (m) => m.id === action.payload.memberId
      );
      if (signedOutMember) {
        updateMemberMessages(signedOutMember);
      }

      // Update in selectedMember
      if (state.selectedMember?.id === action.payload.memberId) {
        updateMemberMessages(state.selectedMember);
      }
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
    },
    setForgeLevels: (state, action: PayloadAction<ForgeLevel[]>) => {
      state.forgeLevels = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMemberStatsAsync.pending, (state) => {
        state.isLoadingStats = true;
        state.error = null;
      })
      .addCase(fetchMemberStatsAsync.fulfilled, (state, action) => {
        state.isLoadingStats = false;
        state.allMemberStats[action.payload.memberId] = action.payload.stats;
      })
      .addCase(fetchMemberStatsAsync.rejected, (state, action) => {
        state.isLoadingStats = false;
        state.error =
          action.error.message || "Failed to fetch member statistics";
      })
      .addCase(fetchMemberKudosAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchMemberKudosAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.memberKudos = action.payload;
      })
      .addCase(fetchMemberKudosAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to fetch member kudos";
      })
      .addCase(fetchForgeLevelsAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchForgeLevelsAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.forgeLevels = action.payload;
      })
      .addCase(fetchForgeLevelsAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to fetch forge levels";
      });
  },
});

export const {
  setSignedInMembers,
  setSignedOutMembers,
  setMemberTypes,
  setActivities,
  selectMember,
  clearSelectedMember,
  signInMember,
  signOutMember,
  toggleMessageReadStatus,
  setLoading,
  setError,
  addKudos,
  setKudos,
  setMemberKudos,
  setForgeLevels,
} = membersSlice.actions;

export default membersSlice.reducer;
