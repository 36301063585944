import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Autocomplete,
  Box,
  Chip,
  Typography,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Member, KudosFormData } from "../../types";

interface KudosDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (data: KudosFormData) => void;
  allMembers: Member[];
}

export const KudosDialog: React.FC<KudosDialogProps> = ({
  open,
  onClose,
  onSubmit,
  allMembers,
}) => {
  const [selectedMembers, setSelectedMembers] = useState<Member[]>([]);
  const [message, setMessage] = useState("");

  const handleSubmit = () => {
    if (selectedMembers.length > 0 && message.trim()) {
      onSubmit({
        recipients: selectedMembers,
        message: message.trim(),
      });
      // Reset form
      setSelectedMembers([]);
      setMessage("");
      onClose();
    }
  };

  const handleClose = () => {
    setSelectedMembers([]);
    setMessage("");
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h5" component="div" align="center" gutterBottom>
          Give Kudos
        </Typography>
        <IconButton onClick={handleClose} sx={{ color: "white" }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2, mt: 1 }}>
          <Autocomplete
            multiple
            options={allMembers}
            getOptionLabel={(member) =>
              `${member.preferredName} ${member.lastName}`
            }
            value={selectedMembers}
            onChange={(_, newValue) => setSelectedMembers(newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Recipients"
                placeholder="Search members..."
              />
            )}
            renderTags={(value, getTagProps) =>
              value.map((member, index) => (
                <Chip
                  label={`${member.preferredName} ${member.lastName}`}
                  {...getTagProps({ index })}
                />
              ))
            }
          />
          <TextField
            label="Message"
            multiline
            rows={4}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Write your kudos message here..."
            fullWidth
          />
          {selectedMembers.length === 0 && (
            <Typography variant="caption" color="text.secondary">
              Please select at least one member to receive kudos
            </Typography>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          disabled={selectedMembers.length === 0 || !message.trim()}
        >
          Send Kudos
        </Button>
      </DialogActions>
    </Dialog>
  );
};
