import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import { Provider } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Layout } from "./components/Layout";
import { MemberList } from "./components/MemberList";
import { MemberDashboard } from "./components/MemberDashboard";
import { theme } from "./theme";
import { store } from "./store";
import { useAppSelector } from "./store";
import { useDataSync } from "./hooks/useDataSync";
import CssBaseline from "@mui/material/CssBaseline";
import { Snackbar, Alert } from "@mui/material";

const MainContent: React.FC = () => {
  const selectedMember = useAppSelector(
    (state) => state.members.selectedMember
  );
  const error = useAppSelector((state) => state.members.error);

  // Initialize data sync
  useDataSync();

  // Handle error snackbar
  const [showError, setShowError] = React.useState(false);

  React.useEffect(() => {
    if (error) {
      setShowError(true);
    }
  }, [error]);

  const handleErrorClose = () => {
    setShowError(false);
  };

  return (
    <>
      <Routes>
        <Route
          path="/"
          element={selectedMember ? <MemberDashboard /> : <MemberList />}
        />
      </Routes>

      <Snackbar
        open={showError && !!error}
        autoHideDuration={6000}
        onClose={handleErrorClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleErrorClose}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {error}
        </Alert>
      </Snackbar>
    </>
  );
};

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Layout>
            <MainContent />
          </Layout>
        </ThemeProvider>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
