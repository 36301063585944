import React from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  List,
  ListItem,
  Chip,
  useTheme,
} from "@mui/material";
import { useAppDispatch } from "../../store";
import {
  toggleMessageReadStatusAsync,
  toggleMessageReadStatus,
} from "../../store/slices/membersSlice";
import { Member } from "../../types";
import DOMPurify from "dompurify";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

interface MemberMessagesProps {
  member: Member;
}

const formatMessageDate = (date: string) => {
  return new Date(date).toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
};

export const MemberMessages: React.FC<MemberMessagesProps> = ({ member }) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const handleToggleRead = (messageId: string) => {
    // Update UI immediately
    dispatch(toggleMessageReadStatus({ memberId: member.id, messageId }));
    // Then make the API call
    dispatch(toggleMessageReadStatusAsync({ memberId: member.id, messageId }));
  };

  const renderMessageContent = (content: string) => {
    // Check if content contains HTML tags
    const containsHTML = /<[a-z][\s\S]*>/i.test(content);

    if (containsHTML) {
      // Handle as RTF/HTML
      const sanitizedHTML = DOMPurify.sanitize(content, {
        ALLOWED_TAGS: [
          "b",
          "i",
          "em",
          "strong",
          "p",
          "br",
          "ul",
          "ol",
          "li",
          "a",
        ],
        ALLOWED_ATTR: ["href", "target", "rel"],
      });
      return <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />;
    } else {
      // Handle as Markdown
      return (
        <ReactMarkdown
          remarkPlugins={[remarkGfm]}
          components={{
            a: ({ node, ...props }) => (
              <a
                {...props}
                style={{
                  color: theme.palette.primary.main,
                  textDecoration: "none",
                }}
                target="_blank"
                rel="noopener noreferrer"
              />
            ),
            p: ({ children }) => (
              <Typography variant="body1" sx={{ my: 0.5 }}>
                {children}
              </Typography>
            ),
            ul: ({ children }) => (
              <Box component="ul" sx={{ ml: 2 }}>
                {children}
              </Box>
            ),
            ol: ({ children }) => (
              <Box component="ol" sx={{ ml: 2 }}>
                {children}
              </Box>
            ),
          }}
        >
          {content}
        </ReactMarkdown>
      );
    }
  };

  const hasMessages =
    Array.isArray(member.messages) && member.messages.length > 0;

  const unreadMessages =
    member.messages?.filter((message) => !message.readDate) || [];
  const readMessages =
    member.messages?.filter((message) => message.readDate) || [];

  return (
    <Card>
      <CardContent>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Messages
        </Typography>
        {!hasMessages ? (
          <Box
            sx={{
              backgroundColor: "#444444",
              borderRadius: 1,
              p: 3,
              textAlign: "center",
              color: "white",
            }}
          >
            <Typography variant="body1">
              You have no messages at this time.
            </Typography>
          </Box>
        ) : (
          <>
            {unreadMessages.length > 0 && (
              <>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Unread Messages
                </Typography>
                <List>
                  {unreadMessages.map((message) => (
                    <ListItem
                      key={message.id}
                      onClick={() => handleToggleRead(message.id)}
                      sx={{
                        minHeight: "100px",
                        backgroundColor: message.important
                          ? "rgba(255, 165, 0, 0.1)" // Orange background with low opacity
                          : "#444444",
                        borderRadius: 1,
                        mb: 2,
                        border: `1px solid ${theme.palette.divider}`,
                        position: "relative",
                        cursor: "pointer",
                        display: "flex",
                        flexDirection: "column",
                        padding: 2,
                        "&:hover": {
                          backgroundColor: "#4a4a4a",
                        },
                      }}
                    >
                      {/* Badges */}
                      <Box
                        sx={{
                          position: "absolute",
                          top: -12,
                          right: 12,
                          display: "flex",
                          gap: 1,
                          zIndex: 1,
                        }}
                      >
                        {message.important && (
                          <Chip
                            label="Important!"
                            color="warning"
                            size="small"
                            sx={{ borderRadius: 1 }}
                          />
                        )}
                      </Box>

                      {/* Message Content */}
                      <Box
                        sx={{
                          flex: 1,
                          display: "flex",
                          alignItems: "center",
                          color: "white",
                          textAlign: "left",
                          width: "100%",
                          "& a": {
                            color: theme.palette.primary.main,
                            textDecoration: "none",
                            "&:hover": {
                              textDecoration: "underline",
                            },
                          },
                        }}
                      >
                        {renderMessageContent(message.message)}
                      </Box>

                      {/* Date */}
                      <Box
                        sx={{
                          alignSelf: "flex-end",
                          mt: 1,
                        }}
                      >
                        <Typography
                          variant="body2"
                          sx={{ color: "rgba(255,255,255,0.7)" }}
                        >
                          {formatMessageDate(message.messageDate)}
                        </Typography>
                      </Box>
                    </ListItem>
                  ))}
                </List>
              </>
            )}
            {readMessages.length > 0 && (
              <>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Read Messages
                </Typography>
                <List>
                  {readMessages.map((message) => (
                    <ListItem
                      key={message.id}
                      onClick={() => handleToggleRead(message.id)}
                      sx={{
                        minHeight: "100px",
                        backgroundColor: message.important
                          ? "rgba(255, 165, 0, 0.1)" // Orange background with low opacity
                          : "#444444",
                        borderRadius: 1,
                        mb: 2,
                        border: `1px solid ${theme.palette.divider}`,
                        position: "relative",
                        cursor: "pointer",
                        display: "flex",
                        flexDirection: "column",
                        padding: 2,
                        "&:hover": {
                          backgroundColor: "#4a4a4a",
                        },
                      }}
                    >
                      {/* Badges */}
                      <Box
                        sx={{
                          position: "absolute",
                          top: -12,
                          right: 12,
                          display: "flex",
                          gap: 1,
                          zIndex: 1,
                        }}
                      >
                        {message.important && (
                          <Chip
                            label="Important!"
                            color="warning"
                            size="small"
                            sx={{ borderRadius: 1 }}
                          />
                        )}
                      </Box>

                      {/* Message Content */}
                      <Box
                        sx={{
                          flex: 1,
                          display: "flex",
                          alignItems: "center",
                          color: "white",
                          textAlign: "left",
                          width: "100%",
                          "& a": {
                            color: theme.palette.primary.main,
                            textDecoration: "none",
                            "&:hover": {
                              textDecoration: "underline",
                            },
                          },
                        }}
                      >
                        {renderMessageContent(message.message)}
                      </Box>

                      {/* Date */}
                      <Box
                        sx={{
                          alignSelf: "flex-end",
                          mt: 1,
                        }}
                      >
                        <Typography
                          variant="body2"
                          sx={{ color: "rgba(255,255,255,0.7)" }}
                        >
                          {formatMessageDate(message.messageDate)}
                        </Typography>
                      </Box>
                    </ListItem>
                  ))}
                </List>
              </>
            )}
          </>
        )}
      </CardContent>
    </Card>
  );
};
