import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface MessageState {
  pendingMessage: string;
}

const initialState: MessageState = {
  pendingMessage: "",
};

const messageSlice = createSlice({
  name: "message",
  initialState,
  reducers: {
    setPendingMessage: (state, action: PayloadAction<string>) => {
      state.pendingMessage = action.payload;
    },
    clearPendingMessage: (state) => {
      state.pendingMessage = "";
    },
  },
});

export const { setPendingMessage, clearPendingMessage } = messageSlice.actions;
export default messageSlice.reducer;
